import React, { Suspense } from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import {
  ErrorBoundary,
  NotificationsProvider,
  SplitPanelProvider,
  ToolsProvider,
  ConfirmProvider
} from '@wfo/common-components';
import '@amzn/awsui-components-react/index.css';
import '@amzn/awsui-global-styles/polaris.css';
import { AppProvider } from './context/appContext';
import App from './components/App';
import logger from './utilities/logger';
import './i18n';
import { LoadingOverlay } from '@wfo/common-components';

/* istanbul ignore next */
if (process.env.NODE_ENV === 'development') {
  const { worker } = require('./utilities/mock-server');
  worker.start();
}

/* istanbul ignore next */
const errorHandler = (error: Error | null, info: object) => {
  error && logger.error('ErrorBoundary', { error, info } as any);
};

ReactDOM.render(
  <div className="awsui awsui-motion">
    <Suspense fallback={<LoadingOverlay show />}>
      <ErrorBoundary onError={errorHandler}>
        <BrowserRouter>
          <NotificationsProvider>
            <ToolsProvider>
              <SplitPanelProvider>
                <ConfirmProvider>
                  <AppProvider>
                    <App />
                  </AppProvider>
                </ConfirmProvider>
              </SplitPanelProvider>
            </ToolsProvider>
          </NotificationsProvider>
        </BrowserRouter>
      </ErrorBoundary>
    </Suspense>
  </div>,
  document.getElementById('root')
);
