import styled from 'styled-components';
import * as awsui from '@amzn/awsui-design-tokens';

export const StyledHeader = styled.header`
  display: block;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1002;
  margin: 0;
  padding: 0 30px;
  background: ${awsui.colorBackgroundHomeHeader};

  .container {
    background-color: black;
  }

  .menu-list {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;

    & > li {
      padding: 0 4px;
      margin: 13px 0 9px;

      a,
      div {
        float: left;
        color: ${awsui.colorTextHomeHeaderDefault};
        line-height: 16px;
      }

      a,
      a:hover {
        cursor: pointer;
        text-decoration: none;
      }

      &.title {
        font-weight: bold;
      }

      &.selected > a {
        font-weight: bold;
        color: ${awsui.colorTextAccent};
      }

      &.separated {
        border-left: 1px solid #3d526c;
        padding-left: 9px;
        margin-left: 9px;
      }

      &.ml-auto {
        margin-left: auto;
      }
    }
  }
`;

export const HelpSelectorText = styled.div`
  margin-left: 5px;
  color: 'white';
`;

export const HelpSelectorIcon = styled.div`
  margin: -2px 0 2px 2px;
`;

interface LanguageSelectorTextProps {
  blackColor: boolean;
}

export const LanguageSelectorText = styled.div<LanguageSelectorTextProps>`
  margin-left: 5px;
  color: ${props => (props.blackColor ? 'black !important' : 'white')};
`;

export const LanguageSelectorIcon = styled.div`
  margin: -2px 0 2px 2px;
`;
