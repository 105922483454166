import React from 'react';
import { Link } from 'react-router-dom';
import { LanguageSelector } from './language-selector/LanguageSelector';
import { HelpSelector } from './help-selector/HelpSelector';
import RegionSelector from './region-selector/RegionSelector';
import { RegionAccessor } from './region-selector/region-accessor';
import { useTranslation } from 'react-i18next';

interface HeaderProps {
  showOptions: boolean;
  showRegionSelector?: boolean;
  regionAccessor?: RegionAccessor;
  onLogout?: () => void;
  showCnRegion?: boolean;
}

const Header: React.FC<HeaderProps> = ({
  showOptions,
  showRegionSelector,
  regionAccessor,
  onLogout,
  showCnRegion = false
}) => {
  const { t } = useTranslation('common');
  const logoutHandler = (event: React.MouseEvent) => {
    event.preventDefault();
    if (onLogout) {
      onLogout();
    }
  };

  // TODO: Create header using Polaris components https://sim.amazon.com/issues/CSOPTIMUS-1111
  return (
    <div>
      <ul className="menu-list">
        <li className="title">
          <Link to="/">
            <>
              <div>
                <img src="/img/amazon_logo.png" height="21" width="21" alt="" />
              </div>
              Vibe
              <span className="awsui-util-hide">Home Page</span>
            </>
          </Link>
        </li>

        <li className="ml-auto" />

        {showRegionSelector && regionAccessor && (
          <RegionSelector regionAccessor={regionAccessor} showCnRegion={showCnRegion} />
        )}

        <li className="separated">
          <LanguageSelector />
        </li>

        {showOptions && (
          <>
            <li className="separated">
              <HelpSelector />
            </li>
            <li className="separated">
              <a href="/" onClick={logoutHandler}>
                {t('vibe_logout')}
              </a>
            </li>
          </>
        )}
      </ul>
    </div>
  );
};

export default Header;
