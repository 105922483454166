import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { LoadingOverlay } from '@wfo/common-components';

import { fetchConfig, fetchUserData, AppConfig, setCnCookieCall } from './authService';
import { handleTokens, invalidateSession } from './authHelpers';
import logger from '../utilities/logger';
import { configureAnalytics } from '../utilities/analytics';

interface IAppContext {
  user: string | null;
  config: AppConfig | null;
  logout: () => void;
}

const initalAppContext: IAppContext = { config: null, user: null, logout: () => {} };
const AppContext = createContext<IAppContext>(initalAppContext);

function AppProvider(props: React.Props<any>) {
  const [loadingUser, setLoadingUser] = useState(true);
  const [user, setUser] = useState<string | null>(null);
  const [config, setConfig] = useState<AppConfig | null>(null);

  const logout = useCallback(() => {
    if (config) {
      invalidateSession([config.LANDING_PAGE_COOKIE_NAME, config.AUTH_REGION_COOKIE_NAME]);
    }
    setUser(null);
  }, [config]);

  useEffect(() => {
    const doInit = async () => {
      try {
        const cfgData = await fetchConfig();
        setConfig(cfgData);

        handleTokens(cfgData.LANDING_PAGE_COOKIE_NAME);
        try {
          const userData = await fetchUserData(cfgData.GET_USER_IDENTITY_API_URL);
          setUser(userData);

          configureAnalytics(cfgData.amplify, userData);
        } catch (err) {
          setUser(null);
          logger.error('Login Error', err);
        }

        try {
          const response = await setCnCookieCall(cfgData);
          console.log('CN Cookie Response: ', response);
        } catch (err) {
          logger.error('Error setting CN cookie', err);
        }
      } finally {
        setLoadingUser(false);
      }
    };

    doInit();
  }, []);

  if (loadingUser) {
    return <LoadingOverlay show />;
  }

  return <AppContext.Provider value={{ user, config, logout }} {...props} />;
}

function useAppContext() {
  const context = useContext(AppContext);
  if (context === initalAppContext) {
    throw new Error('useAppContext must be used within AppProvider');
  }
  return context;
}

export { AppProvider, useAppContext };
